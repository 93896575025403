import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import { MonitorProvider } from "./contexts/MonitorContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

window.monitor_server =
  window.ENV?.REACT_APP_MONITOR_SERVER || process.env.REACT_APP_MONITOR_SERVER;

root.render(
  <React.StrictMode>
    <MonitorProvider>
      <App />
    </MonitorProvider>
  </React.StrictMode>
);
