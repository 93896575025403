import moment from "moment";
import React, { useState, useEffect, useCallback, useRef } from "react";

const MonitorContext = React.createContext([{}, () => {}]);

window.monitor_ws = null;

const MonitorProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [clients, setClients] = useState([]);
  const local_clients = useRef([]);
  const sendData = (data) => {
    try {
      if (window.monitor_ws) {
        window.monitor_ws.send(
          JSON.stringify({
            ...data,
            time: moment(),
          })
        );
      }
    } catch (err) {}
  };

  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("Hyper monitor connecting to server");

    window.monitor_ws = new WebSocket(window.monitor_server);
    setWS(window.monitor_ws);
  }

  useEffect(() => {
    if (window.monitor_ws) {
      let timeout;

      window.monitor_ws.onopen = () => {
        setWSStatus(1);
        console.log("Hyper monitor on open");
        sendData({ type: "monitor" });
        clearInterval(window.monitor_keep_alive);
        window.monitor_keep_alive = setInterval(() => {
          window.monitor_ws.send(
            JSON.stringify({
              type: "monitor",
              monitor_keep_alive: Date.now(),
            })
          );
        }, 20000);
      };
      window.monitor_ws.onmessage = (data) => {
        try {
          data = JSON.parse(data.data);
          console.log("Hyper monitor on message");

          setClients(data.clients);
        } catch (err) {
          console.log(err);
        }
      };
      window.monitor_ws.onerror = (err) => {
        console.log("Hyper monitor on message");
        ws.close();
      };
      window.monitor_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Hyper monitor on close");
        clearTimeout(timeout);
        clearInterval(window.monitor_keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  const gotMessage = useCallback(() => {}, [clients]);

  return (
    <MonitorContext.Provider
      value={{
        sendData,
        clients,
      }}
    >
      {props.children}
    </MonitorContext.Provider>
  );
};

export { MonitorContext, MonitorProvider };
