import React, { useRef, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import HyperLogo from "../images/HyperLogo.png";

import gql from "graphql-tag";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Button from "../ui/form/Button";
import Input from "../ui/form/Input";
import Row from "../ui/Row";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      _id
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 368px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
`;

const LogoContainer = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Logo = styled.img`
  height: 50px;
`;
const Form = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 4px 0px rgb(0 0 0 / 16%);
  border-radius: 4px;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
`;
const Message = styled.div`
  display: flex;
  height: 20px;
  padding-left: 0.5em;
`;
export default function Login({ loggedIn }) {
  const [login, { data }] = useMutation(LOGIN, {
    client: user_client,
    onCompleted: (data) => {
      console.log("completed");
      localStorage.setItem("user-id", data.login._id);
      localStorage.setItem("token", data.login.token);
      loggedIn();
    },
  });

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    function keyDown(e) {
      if (e.keyCode === 13) {
        login({
          variables: {
            username: username,
            password: password,
          },
        });
      }
    }
    window.addEventListener("keydown", keyDown);
    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  }, [username, password]);

  return (
    <Main>
      <LogoContainer>
        <Logo src={HyperLogo} />
      </LogoContainer>
      {/* <h1>Welcome</h1> */}
      <Form>
        <Row>
          <Input
            placeholder="Username"
            name="username"
            value={username}
            onChange={(e) => {
              setUsername(e.currentTarget.value);
            }}
          ></Input>
        </Row>
        <Row>
          <Input
            placeholder="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
          ></Input>
        </Row>
        <Row>
          <Button
            style={{ width: "100%" }}
            primary
            onClick={() => {
              login({
                variables: {
                  username: username,
                  password: password,
                },
              });
            }}
            label="Login"
          />
        </Row>
      </Form>
    </Main>
  );
}
